const _temp0 = require("./asb.svg");
const _temp1 = require("./blobs.svg");
const _temp2 = require("./blobs2.svg");
const _temp3 = require("./blobs3.svg");
const _temp4 = require("./ccxt.svg");
const _temp5 = require("./circles.svg");
const _temp6 = require("./dialogue.svg");
const _temp7 = require("./email-2.svg");
const _temp8 = require("./email-3.svg");
const _temp9 = require("./email.svg");
const _temp10 = require("./eyes-2.svg");
const _temp11 = require("./eyes.svg");
const _temp12 = require("./favicon.svg");
const _temp13 = require("./fish.svg");
const _temp14 = require("./fish2.svg");
const _temp15 = require("./github.svg");
const _temp16 = require("./go-up.svg");
const _temp17 = require("./go-up2.svg");
const _temp18 = require("./go-up2old.svg");
const _temp19 = require("./go-up3.svg");
const _temp20 = require("./lamp.svg");
const _temp21 = require("./point.svg");
const _temp22 = require("./print.svg");
const _temp23 = require("./stackoverflow.svg");
const _temp24 = require("./swirl.svg");
const _temp25 = require("./telegram.svg");
module.exports = {
  "asb": _temp0,
  "blobs": _temp1,
  "blobs2": _temp2,
  "blobs3": _temp3,
  "ccxt": _temp4,
  "circles": _temp5,
  "dialogue": _temp6,
  "email-2": _temp7,
  "email-3": _temp8,
  "email": _temp9,
  "eyes-2": _temp10,
  "eyes": _temp11,
  "favicon": _temp12,
  "fish": _temp13,
  "fish2": _temp14,
  "github": _temp15,
  "go-up": _temp16,
  "go-up2": _temp17,
  "go-up2old": _temp18,
  "go-up3": _temp19,
  "lamp": _temp20,
  "point": _temp21,
  "print": _temp22,
  "stackoverflow": _temp23,
  "swirl": _temp24,
  "telegram": _temp25
}