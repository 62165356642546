To this day: Посегодня
Profile: Резюме
Employment History: Опыт работы
Projects: Проекты
Education: Образование
Skills: Навыки
Languages: Языки
References: Рекомендации
To the top: К началу
Change Theme: Сменить тему
Switch Language: Сменить язык
