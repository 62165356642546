version: 0.1

firstName: Denis
lastName: Voropaev
position: Lead Software Engineer
location: Moscow
photo: photo
description: |
  I am a Lead Software Engineer, Architect, and Full-Stack Developer with over a decade of experience, holding a degree in Applied Mathematics and Physics from Moscow Aviation Institute.

  Adaptable to diverse environments, I thrive equally well in large teams, startups, and contract-based projects. My extensive background includes roles as a Software Architect, Team Lead, and SCRUM master in Agile, Waterfall, and XP teams.

  I co-founded the widely acclaimed open-source library [CCXT](https://github.com/ccxt/ccxt), boasting 30k stars on GitHub.

  In addition to my core expertise, I possess proficiency in low-level APIs (sys/socket.h, net/bpf.h, pthread.h, signal.h), along with experience in Pandas, PyTorch, Jupyter, WebGL, WebRTC, and other technologies.

  Passionate about AI/ML, open source, decentralization, and peer-to-peer systems, I am dedicated to continuous skill enhancement and actively seek new business connections, opportunities, and individuals to learn from.

links:
  - icon: github
    title: GitHub
    href: https://github.com/tankakatan
  - icon: stackoverflow
    title: Stack Overflow
    href: https://stackoverflow.com/u/4228476
  - icon: telegram
    title: Telegram
    href: https://t.me/neodequate
  - icon: email
    title: Email
    href: d.n.voropaev@gmail.com

skills:
  - title: Node.js
    progress: 100
  - title: React
    progress: 100
  - title: MongoDB
    progress: 100
  - title: Python
    progress: 100
  - title: Docker
    progress: 100
  - title: Objective-C
    progress: 100
  - title: Solidity
    progress: 80
  - title: Linux
    progress: 80
  - title: C
    progress: 80

languages:
  - title: Russian
    progress: 100
    caption: Native
  - title: English
    progress: 80
    caption: B2 - Upper Intermediate

hobbies: 
  - Guitar
  - Electronic music
  - Traveling and hiking
  - Micro-controllers

employment:
  - position: Senior Software Engineer, Architect
    company: Self-employed
    since: 2023-01
    location: Moscow, Russia
    description: |
      As an independent entrepreneur, I design and construct commercial software on a contractual basis, offer consultations, and drive research and development. I have successfully delivered various products, including crypto-trading bots and L2 blockchain data aggregators. I am actively engaged in ongoing research and self-development in the field of AI/ML.

    stack:
      - Python
      - Numpy
      - PyTorch
      - TensorFlow
      - Node.js
      - React
      - Graph protocol

  - position: Lead Software Engineer
    company: Point Network
    since: 2021-01
    until: 2022-12
    location: Dubai, UAE
    description: |
      Point Network is an open-source, holistic Web3 implementation encompassing:
        - Decentralized storage
        - A blockchain featuring the Point Token
        - Decentralized identity system
        - A browser with a secure multi-chain wallet
        - A comprehensive infrastructure for dapp creation and deployment
        - Fully functional dapps, including Social Network, Email, Drive, and others
        - ENS, SNS integration, and numerous additional features

      As one of the initial engineers to join the team, I assumed a pivotal role in the project, overseeing responsibilities such as design and implementation of the core functionality, CI/CD and infrastructure maintenance, team management, candidate interviews and assessments, tech support, and more. Through my direct involvement, the team achieved:
        - An [honourable mention](https://solana.com/news/solana-ignition-hackathon-winners) in the Solana Ignition Hackathon 2021
        - Successful completion of a series A funding round
        - Attraction and onboarding of highly skilled developers
        - Transformation of the MVP into a fully realized commercial product
        - Establishment of a robust CI/CD workflow, leading to the publication of the initial product version
        - Increase in Daily Active Users (DAU) to 5,000
        - Commencement of the Main Net based on Polygon, including the attraction of validators
        - [Listing](https://www.coingecko.com/en/coins/point-network) of Point Token on multiple major cryptocurrency exchanges

    stack:
      - Node.js
      - Typescript
      - React
      - Solidity
      - Arweave
      - Polygon
      - Docker
      - MinIO
      - RabbitMQ
      - ELK
      - GitHub actions

  - position: Senior Software Engineer
    company: Aggregion
    since: 2020-09
    until: 2021-09
    location: NYC, US
    description: |
      Aggregion is a decentralized data collaboration platform enabling ML models, data research, analysis, marketing campaigns, and more against user data from various independent data providers, ensuring compliance with user privacy legislation.

      I spearheaded the backend design and implementation of auditory segment construction and client order placement. Collaborating with colleagues, I developed a workflow for securely specifying queries to build auditory segments and upload obfuscated user data to advertising platforms like Facebook, Yandex, MyTarget, VK, Beeline, and others.

    stack:
      - Node.js
      - Typescript
      - GraphQL
      - MongoDB
      - Mongoose
      - Docker
      - RabbitMQ
      - Mocha

  - position: Co-founder, Software Architect
    company: The 1709 Company
    since: 2018-04
    until: 2020-12
    location: George Town, Grand Cayman
    description: |
      1709 is a high-frequency arbitrage trading platform managing over 100 accounts across 25+ crypto exchanges including DEXs.

      As one of the four company founders, I took charge of collecting and ensuring the consistency of trading and funding history, as well as constructing analytics and reports.

      In close collaboration with the team, I designed, implemented, and integrated the following functionalities:
        - Real-time multi-asset account balance monitor
        - Pagination-agnostic crypto asset operations history tracker
        - Tools for private Web API scraping
        - Secure password and key manager
        - UI for trading and funding history visualization and verification
        - Tax report generator
        - Automatic crypto asset rebalancer

    stack:
     - Node.js
     - MongoDB
     - Python
     - Jupyter
     - Pandas
     - Puppeteer
     - Docker
     - Linux
     - AWS EC2
     - CCXT
     - Mocha

  - position: Senior Software Engineer
    company: CCXT
    since: 2018-02
    until: 2020-03
    location: Moscow, Russia
    description: |
      I co-founded the renowned crypto exchange library CCXT, boasting 30k stars on GitHub. The library provides a unified API in three programming languages for hundreds of top crypto exchanges, including DEXs.

      Collaborating with fellow founders, I implemented API methods for retrieving asset operations history, abstracting from API pagination differences, and actively contributed to client-side integrations and marketing solutions.

    stack:
      - Node.js
      - JavaScript
      - Python
      - PHP
      - Travis CI
      - Appveyor
      - Docker

  - position: Lead Software Engineer
    company: Sberbank
    since: 2016-08
    until: 2018-02
    location: Moscow, Russia
    description: |
      In Russia's leading bank I joined a new team dedicated to developing an in-house iPad app for CIB (multinational corporate investment banking and asset management) bank managers. The app encompassed features such as a calendar, task planner, client profiles, manager profiles, knowledge base, event manager, and more.

      Taking charge of the front-end development from inception, I assumed roles as both a front-end team lead and SCRUM master in our Agile team. My responsibilities extended to close collaboration with business analysts, inter-team coordination, and the interviewing and assessment of new team members. Additionally, I established a CI/CD pipeline, significantly expediting development and enabling multiple releases per day.

      Under my leadership, the team successfully completed two super-sprints and garnered positive feedback from users.

    stack:
      - ReactNative
      - Typescript
      - Node.js
      - Mocha
      - Redux
      - Objective-C
      - Swift
      - XCTest
      - Docker
      - Jenkins

  - position: Senior Software Engineer, Architect
    company: ASB (Russian Student Basketball Association)
    since: 2015-09
    until: 2016-10
    location: Moscow, Russia
    description: |
      As the sole developer, I designed, developed and launched an iPhone app featuring:
        - Championship/league standings
        - Team profiles
        - Player profiles
        - Game profiles
        - News / Media

      Additionally I developed server-side endpoints for news feed and videos and photos associated with specific players and teams.

    stack:
      - Objective-C
      - Swift
      - Core Data
      - GCD
      - Operation queue
      - WebKit
      - PHP
      - MySQL
      - JavaScript

projects: 
  - name: Point Network
    description: The first ever wholistic Web3 implementation
    link: https://pointnetwork.io
    img: point
  - name: CCXT
    description: CryptoCurrency eXchange Trading Library
    link: https://ccxt.trade
    img: ccxt
  - name: ASB app
    description: Russian Student Basketball Accociation iPhone app
    link: https://asbasket.ru
    img: asb

education:
  - position: Engineer's degree
    company: Moscow Aviation Institute
    since: 2001-09
    until: 2007-02
    location: Moscow, Russia
    description: |
      Graduate of the faculty of Applied Mathematics and Physics. Thesis: Computation of a depth-map of stereoscopic images.

references:
  - name: Vitaly Gordon
    company: CCXT
    contacts:
      - rocket.mind@gmail.com
  - name: Peter Opdahl
    company: The 1709 Company
    contacts:
      - peter@1709.co
      - peter@ito-group.com
