location: ''
description: |
  I am a Lead Software Engineer, Architect, and Full-Stack Developer with over a decade of experience, holding a degree in Applied Mathematics and Physics from Moscow Aviation Institute.

  Adaptable to diverse environments, I thrive equally well in large teams, startups, and contract-based projects. My extensive background includes roles as a Software Architect, Team Lead, and SCRUM master in Agile, Waterfall, and XP teams.

  I co-founded the widely acclaimed open-source library [CCXT](https://github.com/ccxt/ccxt), boasting 30k stars on GitHub.

  In addition to my core expertise, I possess proficiency in low-level APIs (sys/socket.h, net/bpf.h, pthread.h, signal.h), along with experience in Pandas, PyTorch, Jupyter, WebGL, WebRTC, and other technologies.

  Passionate about AI/ML, open source, decentralization, and peer-to-peer systems, I am dedicated to continuous skill enhancement and actively seek new business connections, opportunities, and individuals to learn from.

links:
  - icon: ''
    title: Website
    href: https://denisvoropaev.com
  - icon: github
    title: GitHub
    href: https://github.com/tankakatan
  - icon: stackoverflow
    title: Stack Overflow
    href: https://stackoverflow.com/u/4228476
  - icon: telegram
    title: Telegram
    href: '@neodequate'
  - icon: email
    title: Email
    href: d.n.voropaev@gmail.com
