firstName: Денис
lastName: Воропаев
position: Ведущий Инженер-программист
location: Москва
description: |
  Я Ведущий Инженер / Архитектор / Full-stack Разработчик ПО с опытом более 10 лет. По образованию Инженер-математик, окончил факультет Прикладной Математики и Физики в Московском Авиационном Институте.

  Открыт к сотрудничеству как с большими компаниями, так и стартапами на всех этапах роста, а так же на контрактной основе. Имею успешный опыт проектирования ПО с нуля и управления Agile, Waterfall и XP командами.

  Являюсь сооснователем популярной библиотеки с открытым кодом — [CCXT](https://github.com/ccxt/ccxt), собравшую 30k звёзд на GitHub.

  Помимо ключевых навыков, я знаком с низкоуровневым API (sys/socket.h, net/bpf.h, pthread.h, signal.h), работаю с Pandas, PyTorch, Jupyter, WebGL, WebRTC и другими технологиями.

  Область моих интересов лежит в ИИ и машинном обучении, Open Source, p2p и децентрализации. Люблю получать знания и развивать навыки. Рад деловым знакомствам и учиться у более опытных.

languages:
  - title: Русский
    caption: Родной
  - title: Английский

hobbies: 
  - Гитара
  - Электронная музыка
  - Путешествия и походы
  - Микро-контроллеры

employment:
  - position: Старший Инженер, Архитектор
    company: Индивидуальный Предприниматель
    location: Москва, Россия
    description: |
      В качестве индивидуального предпринимателя разрабатываю коммерческое ПО на контрактной основе, оказываю консультации и занимаюсь исследовательской деятельностью. Успешно завершил несколько проектов в области крипто-валютной торговли и агрегации данных с L2 блокчейн сетей. Занимаюсь исследованием и саморазвитием в области ИИ и машинного обучения.

  - position: Ведущий Инженер
    location: Дубаи, ОАЭ
    description: |
      Point Network это полноценная реализация Web3 с открытым кодом, представляющая собой:
        - Децентрализованное хранилище
        - Собственный блокчейн и токен
        - Распределённую систему доменных имён / identity
        - Браузер с защищённым крипто-кошельком, работающим в разных сетях
        - Инфраструктуру для создания и публикации децентрализованных web-приложений
        - Полнофункциональные dapps, включая социальную сеть, email, облачный диск и другие
        - Интеграция с ENS, SNS и многое другое

      Присоединившись к проекту в числе первых участников, я принял активное участие в его развитии, взяв ответственность за архитектуру и разработку ядра системы, CI/CD и инфраструктуру, расширение и управление командой, интеграции и много другое. При моём активном участии команда проекта:
        - [Получила](https://solana.com/news/solana-ignition-hackathon-winners) почётное место в хакатоне Solana Ignition 2021
        - Привлекла инвестиции, пройдя раунд серии A
        - Привлекла ряд высококлассных специалистов
        - Развила MVP в полнофункциональный продукт
        - Реализовала полный цикл CI/CD и опубликовала первую версию продукта
        - Увеличила базу уникальных пользователей до 5k ежедневно
        - Провела запуск Main Net на основе Polygon и привлекла ряд валидаторов
        - Провела [листинг](https://www.coingecko.com/en/coins/point-network) токена на нескольких крупных крипто-биржах

  - position: Старший Инженер
    location: Нью Йорк, США
    description: |
      Aggregion — децентрализоавнная платформа для безопасной совместной работы с данными различных поставщиков, позволяющая создавать и запускать ML и аналитические модели, маркетинговые исследования и кампании, программы лояльности и многое другое, с учётом действующего законодательства в отношении приватности данных.

      Совместно с командой я спроектировал и реализовал серверную логику и API для создания сегментов аудиторий и заказов на их использование. Созданный функционал позволил объединять сложные выборки обезличенных данных от разных поставщиков в единые сегменты, готовые к загрузке на такие платформы как Facebook, Yandex, MyTarget, VK, Beeline и другие.

  - position: Сооснователь, Архитектор
    location: Джорджтаун, Острова Кайман
    description: |
      1709 это платформа для высокочастотной торговли, осуществляющая арбитраж, используя более 100 счетов более 25 крипто-бирж, включая децентрализованные.

      Являясь одним из 4 основателей компании, я отвечал за сбор и целостность истории сделок и операций с активами и формирование отчётов и аналитики на её основе.

      Активно взаимодействуя с командой, я спроектировал, разработал и внедрил следующий функционал:
        - Систему мониторинга балансов мультивалютных счетов, работающую в реальном времени
        - Трекер истории криптовалютных операций, инвариантный к множеству реализаций пагинации
        - Набор решений для скрейпинга защищённого Web API
        - Защищённый менеджер паролей и ключей
        - Интерфейс для визуализации и сверки торговой истории
        - Генератор налоговой отчётности
        - Автоматический ребалансировщик активов между счетами

  - position: Старший Инженер
    location: Москва, Россия
    description: |
      Я являюсь сооснователем одной из самых популярных библиотек для криптовалютной торговли — CCXT, собравшей на сегодня 30k звёзд на GitHub. Библиотека предоставляет унифицированный API на 3 языках программирования к сотням самых известных криптовалютных бирж, включая децентрализованные.

      Совместно с командой основателей я разработал методы API для получения истории операций с активами и работы с пагинацией, активно участвовал в интеграции библиотеки с прикладным ПО и маркетинговых решениях.

  - position: Ведущий Инженер
    company: Сбербанк
    location: Москва, Россия
    description: |
      В крупнейшем Российском банке я присоединился к новой команде в составе Сбертех ЕФС, для создания внутреннего iPad приложения для менеджеров CIB (корпоративно-инвестиционного направления), которое включало календарь, планировщик задач, редактируемые профили клиентов и менеджеров, базу знаний, менеджер событий и встреч, и многое другое.

      Начав работу в качестве разработчика, я был назначен ведущим Front-End инженером и SCRUM-мастером Agile-команды, активно взаимодействовал с бизнес-заказчиком, координировал работу команд и проводил собеседования новых сотрудников. Так же я организовал процесс CI/CD, что значительно ускорило разработку, позволив публиковать до нескольких релизов ежедневно.

      Под моим управлением команда дважды выпустила продукт в промышленную эксплуатацию, получив положительные отзывы пользователей.

  - position: Старший Инженер, Архитектор
    company: Ассоциация Студенческого Баскетбола
    location: Москва, Россия
    description: |
      В качестве индивидуального разработчика, я спроектировал, реализовал и опубликовал iPhone приложение ASB, включающее:
        - Турнирные таблицы для чемпионатов и лиг
        - Профили команд
        - Профили игроков
        - Профили матчей
        - Новостную и медиа ленты

      Кроме того, я расширил серверное API, добавив в него методы для получения новостей и видео с выборкой по игрокам, играм и командам.

projects: 
  - description: Первая в мире полноценная реализация Web3
  - description: Унифицированный API для лидирующих крипто-валютных бирж
  - description: iPhone приложение ASB Ассоциации Студенческого Баскетбола

education:
  - position: Инженер-математик
    company: Московский Авиационный Институт
    location: Москва, Россия
    description: |
      Выпускник факультета Прикладной Математики и Физики. Тема деплома: Построекние карты глубины стереографических изображений.

references:
  - name: Виталий Гордон
  - name: Peter Opdahl
